<template>
  <div>
    <div>
      <div>管理员账户</div>
      <input type="text" v-model="admin" v-if="!loginButton.isDisabled" />
      <button @click="login" :disabled="loginButton.isDisabled">
        {{ loginButton.value }}
      </button>
    </div>

    <div>账号(如果账户已存在，会更新账户时间)</div>
    <input type="text" v-model="userName" />
    <div>账户时间</div>
    <div>
      <input type="radio" v-model="expiredMonth" value="1" checked="true" />
      <label>1个月</label>

      <input type="radio" v-model="expiredMonth" value="3" />
      <label>3个月</label>

      <input type="radio" v-model="expiredMonth" value="6" />
      <label>6个月</label>

      <input type="radio" v-model="expiredMonth" value="12" />
      <label>12个月</label>
    </div>
    <button @click="updateUser">增加/更新</button>
    <button @click="deleteUser">删除</button>
    <div v-if="isLogin">
      <ve-table :columns="columns" :table-data="currentTableData"></ve-table>
      <ve-pagination
        @on-page-number-change="pageChange"
        :total="tableData.length"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
      pageIndex: 1,
      pageSize: 10,
      columns: [
        { field: "userName", key: "a", title: "用户名", align: "left" },
        { field: "expiredTime", key: "b", title: "过期时间", align: "left" },
      ],
      tableData: [],
      currentTableData: [],
      admin: "",
      loginButton: {
        isDisabled: false,
        value: "登陆",
      },
      userName: "",
      expiredMonth: "1",
    };
  },
  methods: {
    getCurrentTableData() {
      this.currentTableData = this.tableData.slice(
        (this.pageIndex - 1) * this.pageSize,
        this.pageIndex * this.pageSize
      );
    },
    pageChange(pageIndex) {
      console.log(pageIndex);
      this.pageIndex = pageIndex;
      this.getCurrentTableData();
    },
    login() {
      this.$axios({
        method: "POST",
        url: "/ali/adminLogin",
        data: { admin: this.admin },
      }).then((res) => {
        if (res.data) {
          this.loginButton.isDisabled = true;
          this.loginButton.value = "已登陆";
          this.isLogin = true;
          this.getAllUsers();
        }
      });
    },
    updateTable(users) {
      this.tableData = [];
      for (let user of users) {
        let dateString = new Date(user.expiredTime).toDateString();
        this.tableData.push({
          userName: user.userName,
          expiredTime: dateString,
        });
      }
      this.getCurrentTableData();
    },
    getAllUsers() {
      this.$axios({
        method: "GET",
        url: "/ali/getAllUsers",
      }).then((res) => {
        this.updateTable(res.data);
      });
    },
    updateUser() {
      this.$axios({
        method: "POST",
        url: "/ali/updateUser",
        data: { userName: this.userName, expiredMonth: this.expiredMonth },
      }).then((res) => {
        if (res.data) {
          this.updateTable(res.data);
        } else {
          alert("操作失败");
        }
      });
    },
    deleteUser() {
      this.$axios({
        method: "POST",
        url: "/ali/deleteUser",
        data: { userName: this.userName },
      }).then((res) => {
        if (res.data) {
          this.updateTable(res.data);
        } else {
          alert("操作失败");
        }
      });
    },
  },
};
</script>

<style>
</style>